import React, { Component } from 'react';
import Helmet from 'react-helmet';

import App from '../../../layout/App';
import Panel from '../../../core/Panel';

export class HealthInsurancePolicy extends Component {
  static propTypes = {};

  render() {
    return (
      <App>
        <div className="component terms-of-service-page">
          <Helmet title="Terms of Service – ThinkReservations">
            <meta
              name="description"
              content="This page explains our terms of service, which you agree to when using thinkreservations.com web sites."
            />
          </Helmet>

          <Panel>
            <div className="max-w-5xl mx-auto">
              <h1>ThinkReservations Terms of Service</h1>
              <a href="https://premera.sapphiremrfhub.com/">
                <p>
                  This link leads to the machine-readable files that are made
                  available in response to the federal Transparency in Coverage
                  Rule and includes negotiated service rates and out-of-network
                  allowed amounts between health plans and healthcare providers.
                  The machine-readable files are formatted to allow researchers,
                  regulators, and application developers to access and analyze
                  data more easily.
                </p>
              </a>
            </div>
          </Panel>
        </div>
      </App>
    );
  }
}

export default HealthInsurancePolicy;
